<template>
    <div class="user">
        <div class="login__user wide">
            <h1>"Climate CRON" order form</h1>
            <h3 style="margin-top: 0; background:#F3BF07; padding: 5px 15px; color: black; font-weight: 300; border-radius: 5px;">
                Submit a purchase request directly
            </h3>
            <transition name="fade" mode="out-in">
                <form v-if="orderCreated === false" class="paypal" :key="1">
                    <div class="order-create-form" style="margin-bottom: 0;">
                        <div class="buy-cron-form">
                            <div class="order-create-line">
                                <input-field :type="'number'" :name="'number'" :label="'Number of units'" :inputError="formErrors.coins" disabled="true"></input-field>                                
                                <select @change="setNumber">
                                    <option value="5">5 CRON</option>                                    
                                    <option value="10">10 CRON</option>                                                                    
                                    <option value="25">25 CRON</option>                                                                    
                                    <option value="50">50 CRON</option>                                
                                    <option value="100">100 CRON</option>                            
                                    <option value="500">500 CRON</option>
                                </select>
                            </div>
                            <input-field :type="'text'" :name="'name'" :label="'Your name'" :inputValue="name" :inputError="formErrors.name"
                                @setName="setName"></input-field>        
                            <input-field :type="'text'" :name="'phone'" :label="'Phone number'" :inputValue="phone" :inputError="formErrors.phone"
                                @setPhone="setPhone"></input-field>                            
                            <input-field :type="'text'" :name="'email'" 
                                :inputError="formErrors.email" 
                                :inputValue="email"
                                :label="'Email'"
                                @setEmail="setEmail"></input-field>
                            <p class="subtotal"><span>Total:</span> {{Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format((coins || 0) * course)}}</p>
                            <transition name="fade" mode="out-in">
                                <div class="errors" v-if="displayError === true">
                                    <p class="error" style="margin: 0; padding: 0;">{{error.message}}</p>
                                </div>
                            </transition>                    
                        </div>
                        
                        <div class="sub_checkbox"
                            :class="{'error': formErrors.agree}"
                            style="text-align: center;
                            justify-content: center;
                            width: 100%;">
                            <input type="checkbox" name="agree" id="agree" :checked="isChecked" @change="setAgree">
                            <label for="agree">I agree <router-link :to="{ name: 'terms'}"> Purchase Agreement</router-link></label>
                        </div>
                        <div class="submit" @click="purchaseCoins">{{buttonText}}</div>
                    </div>
                </form>
                <div v-else :key="2" class="registration__progress registerSucceed" style="margin-top: 10px;">
                    <p style="text-align: center; font-weight: 300; line-height: 24px; padding: 0;">You have successfully sent a request for purchasing <span style="border-bottom: 1px solid">{{coins}} Climate CRON</span>.<br/>Our manager will contact you soon</p>            
                </div>
            </transition>
        </div>    
    </div>
</template>
<script>
import axios from 'axios';
import input from '../user/Input.vue';
const countries = require('country-list');

export default {
    data() {
        return {
            buttonText: 'Order',
            expanded: false,
            orderCreated: false,
            filteredCountries: [],
            isCountryListVisible: false,
            coins: 5,
            course: 55000,
            country: '',
            countryCode: '',
            name: '',
            secondName: '',
            email: '',
            state: '',
            address: '',
            city: '',
            postCode: '',
            phoneCode: '',
            phone: '',            
            displayError: false,
            error: {},
            formErrors: {
                coins: false,
                country: false, 
                name: false,
                city: false,
                postCode: false,
                phoneCode: false,
                state: false,
                address: false,
                phone: false,
                secondName: false
            },
            isShifted: false,
            purchaseSuccessful: false,
            isChecked: true,            
            registerErrors: [],
        }
    },
    methods: {
        showCountries: function(e) {
            this.country = e.target.value;
            this.shiftSpan(e);
            let tempCountries = countries.getNames().filter(country => country.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
            if (tempCountries.length === 0) {
                tempCountries.push("<span class='no-matches'>No countries found</span>")
            }
            this.filteredCountries = tempCountries;
            this.formErrors.country = false;
            if (e.target.value.toString().length > 0) {
                this.isCountryListVisible = true;
            } else {
                this.isCountryListVisible = false;
            }
        },
        hideCountries: function() {
            if (this.isCountryListVisible) {
                this.isCountryListVisible = false;
                this.checkCorrectCountry();
            }
        },
        fillCountry: function(e) {
            if (!e.target.classList.contains('no-matches')) {
                this.$refs.country.value = e.target.innerText;
                this.country = e.target.innerText;
            }            
            this.hideCountries();
        },
        setName: function(name) {
            this.name = name;
            this.checkName();
        },
        setSecondName: function(secondName) {
            this.secondName = secondName;
            this.checkSecondName();
        },
        setPhone: function(phone) {
            this.phone = phone;
            this.checkPhone();
        },
        setPhoneCode: function(phoneCode) {
            this.phoneCode = phoneCode;
            this.checkPhoneCode();
        },
        setPostCode: function(postCode) {
            this.postCode = postCode;
            this.checkPostCode();
        },
        setNumber: function(e) {
            this.coins  = +e.target.value;
        },
        setCity: function(city) {
            this.city = city;
            this.checkCity();
        },        
        setAddress: function(address) {
            this.address = address;
            this.checkAddress();
        },      
        setState: function(state) {
            this.state = state;
            this.checkState();
        },
        setEmail: function(email) {
            this.email = email;
            this.checkEmail();
        },     
        setAgree: function(e) {
            this.isChecked = e.target.checked;
            if (this.formErrors.agree && e.target.checked) {
                this.formErrors.agree = false;
            }
        },  
        checkNumber() {
            if (parseInt(this.coins) > 0) {
                this.formErrors.coins = false;
            } else {
                this.formErrors.coins = true;
            }
        },
        checkName() {
            if (this.name.trim() !== "") {
                this.formErrors.name = false;
            } else {
                this.formErrors.name = true;
            }
        },
        checkEmail: function() {
            let pattern = /^([a-z0-9_.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i;
            if (pattern.test(this.email)) {
                this.formErrors.email = false;
            } else {
                this.formErrors.email = true;
            }
            this.updateError('email');
        },
        checkSecondName() {
            if (this.secondName.trim() !== "") {
                this.formErrors.secondName = false;
            } else {
                this.formErrors.secondName = true;
            }                
        },
        checkPhone() {
            if (this.phone.trim() !== "") {
                this.formErrors.phone = false;
            } else {
                this.formErrors.phone = true;
            }                
        },
        checkPhoneCode() {
            if (this.phoneCode.trim() !== "") {
                this.formErrors.phoneCode = false;
            } else {
                this.formErrors.phoneCode = true;
            }                
        },
        checkPostCode() {
            if (this.postCode.trim() !== "") {
                this.formErrors.postCode = false;
            } else {
                this.formErrors.postCode = true;
            }                
        },
        checkCity() {
            if (this.city.trim() !== "") {
                this.formErrors.city = false;
            } else {
                this.formErrors.city = true;
            }                
        },
        checkAddress() {
            if (this.address.trim() !== "") {
                this.formErrors.address = false;
            } else {
                this.formErrors.address = true;
            }                
        },
        checkState() {
            if (this.state.trim() !== "") {
                this.formErrors.state = false;
            } else {
                this.formErrors.state = true;
            }                
        },
        checkAgree: function() {
            this.formErrors.agree = false;
            if (!this.isChecked) {
                this.formErrors.agree = true;
            }
        }, 
        checkCorrectCountry: function() {
            let foundCoutry = countries.getNames().find(country => {
                return country.toLowerCase() === this.$refs.country.value.toLowerCase()
            })
            if (foundCoutry) {
                this.countryCode = countries.getCode(this.country)
                this.formErrors.country = false;
            } else {
                this.formErrors.country = true;
            }
        },         
        updateError: function(type) {
            for (let i = this.registerErrors.length - 1; i >= 0; i--) {
                if (this.registerErrors[i].type === type) {
                    this.registerErrors.splice(i, 1);
                }
            }
            if (this.registerErrors.length === 0) {
                this.displayErrors = false;
            } 
        },
        purchaseCoins() {
            let isError = false;
            this.checkNumber();
            this.checkName();
            this.checkPhone();              
            this.checkEmail();     
            this.checkAgree(); 
            Object.values(this.formErrors).forEach(error => {
                if (error === true) {
                    isError = true;
                }
            })
            this.displayError = false;
            if (isError === true) {
                this.displayError = true;
                this.error.message = `All fields are mandatory.`;
                setTimeout(() => {
                    this.displayError = false
                }, 3000)
                return false;
            }
            axios.post(this.$store.state.postUrl, {
                action: 'presaleCRON',
                coins: this.coins,
                name: this.name,
                email: this.email,
                phone: this.phone,             
                user_ip: this.$store.state.userIp
            }, this.$store.state.config).then(response => {
                if (response.data.result === true) {
                    this.orderCreated = true;
                } else {
                    console.log('h,,,,');
                    this.displayError= true;
                    this.error.message = response.data.result
                    setTimeout(() => {
                        this.displayError = false
                    }, 3000)
                }
            }).catch(e => {
                console.error(e + ': Some error occured, please, reload the page or contact support')
            })            
        },
        goBack() {
            this.orderCreated = false;
        },
        cancelPurchase() {
            this.expanded = false;
        },
        shiftSpan: function(e) {
            e.target.nextSibling.style.top = "-6px";
            e.target.nextSibling.style.fontSize = "10px";
        },
        unshiftSpan: function(e) {
            if (e.target.value.trim() === "") {
                e.target.nextSibling.style.top = "10px";
                e.target.nextSibling.style.fontSize = "13px";
            }            
        }
    },
    components: {
        'input-field': input
    },
    mounted() {
        window.addEventListener('scroll', this.checkScroll);
    }
}
</script>